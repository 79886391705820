"use client";
import { useState, useEffect } from "react";
import Image from "next/image";
import Link from "next/link";
import { useAuth } from "@clerk/nextjs";
import { UserButton } from "@clerk/nextjs";
import { Button } from "@/components/ui/button";
import MobileNavigationBar from "./MobileNavigationBar";

interface NavigationBarProps {
  isDevComplete: boolean;
}

const NavigationBar: React.FC<NavigationBarProps> = ({ isDevComplete }) => {
  const { isSignedIn } = useAuth();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (isMobile) {
    return <MobileNavigationBar />;
  }

  return (
    <nav className="flex justify-between items-center p-6 bg-transparent z-50">
      <Link href="/">
        <Image src="/logo.png" alt="travlogs Logo" width={120} height={40} priority />
      </Link>
      <div className="flex items-center space-x-6">
        {isSignedIn ? (
          <>
            <Link href="/conversation">
              <Button variant="outline" className="rounded-full text-white bg-transparent border-white hover:bg-white hover:text-gray-800 px-6 py-2 text-base">
                dashbaord
              </Button>
            </Link>
            <UserButton afterSignOutUrl="/" />
          </>
        ) : (
          <>
            {isDevComplete ? (
              <>
                <Link href="/sign-up">
                  <Button variant="outline" className="rounded-full text-white bg-transparent border-white hover:bg-white hover:text-gray-800 px-6 py-2 text-base">
                    sign up
                  </Button>
                </Link>
                <Link href="/sign-in">
                  <Button variant="outline" className="rounded-full text-white bg-transparent border-white hover:bg-white hover:text-gray-800 px-6 py-2 text-base">
                    sign in
                  </Button>
                </Link>
              </>
            ) : (
              <Button 
                disabled 
                className="bg-[#333333] text-white border border-white opacity-90 cursor-not-allowed rounded-full hover:bg-[#444444] transition-colors duration-300 px-6 py-2 text-base"
              >
                Coming Soon :)
              </Button>
            )}
          </>
        )}
      </div>
    </nav>
  );
};

export default NavigationBar;
